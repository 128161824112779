.LayersController {
    width: 100%;
    height: auto;
    overflow-y: auto;

    border: 0px;
    border-top: 1px;
    /* border-bottom: 1px; */
    border-style: solid;
    border-color: #F3F4F6;
}

.Title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    align-items: center;
    text-align: center;

    padding-top: 16px;
    padding-bottom: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.LayerPlus {
    background-color: #23813E;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    color: #FFFFFF;
}

.LayerList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.LayerItem {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #F3F4F6;
}

.LayerItem:first-child {
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: #F3F4F6;
}

.LayerItemRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LayerItemName {
    width: 50%;
    cursor: pointer;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
}

.LayerItemNameSelected {
    font-weight: bold;
}

.LayerRenameInput {
    width: 100%;
}

.LayerItemControls {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px
}

.IconButton {
    cursor: pointer;
    border-radius: 2px;
    width: 18px;
    height: 18px;

    background-color: #FFFFFF;
}

.IconButton:active {
    background-color: #F3F4F6;
}

.Eye {
    color: #23813E;
}

.Delete {
    color: #FF320A;
}

.Arrow {
    color: #6B7280;
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    .Title {
        /* gap: calc(12px * 0.75); */
        padding-top: calc(16px * 0.75);
        padding-bottom: calc(12px * 0.75);
        /* font-size: calc(16px * 0.75); */
        /* line-height: calc(20px * 0.75); */
    }
    
    /* .LayerPlus {
        border-radius: calc(4px * 0.75);
        width: calc(24px * 0.75);
        height: calc(24px * 0.75);
    } */
    
    .LayerItem {
        margin-left: calc(16px * 0.75);
        margin-right: calc(16px * 0.75);
        padding-top: calc(12px * 0.75);
        padding-bottom: calc(12px * 0.75);
        /* border-bottom-width: calc(2px * 0.75); */
    }
    
    /* .LayerItem:first-child { */
        /* border-top-width: calc(2px * 0.75); */
    /* } */
    
    
    /* .LayerItemName {
        font-size: calc(14px * 0.75);
        line-height: calc(20px * 0.75);
    } */
    
    .LayerItemControls {
        gap: calc(8px * 0.75);
    }
    
    /* .IconButton {
        border-radius: calc(2px * 0.75);
        width: calc(18px * 0.75);
        height: calc(18px * 0.75);
    }     */
}