.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Link {
    text-decoration: none;
    color: inherit;
    border-color: inherit;
}

.TitleBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #E37222;
    border: 0;
    border-bottom: 1px;
    border-style: solid;
}

.Back {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 10px;
    align-items: center;
    margin: 5px;
}

.Title {
    font-size: 25px;
    font-weight: bold;
}

.Message {
    margin: 5px;
    font-size: 25px;
    overflow-x: scroll;
}

.AddForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin: 10px;

    border: 1px;
    border-style: solid;
}

.FormInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FormPreview {
    width: 150px;
    height: 150px;
    border: 1px;
    border-style: solid;
}

.FormImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.FormSubmit {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.StickerItems {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 80%;
    border: 1px;
    border-style: solid;
}

.Item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px;
    border-style: solid;
    padding: 2px;
}

.ItemPreview {
    width: 200px;
    height: 200px;
    border: 1px;
    border-style: solid;
}
.ItemImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.ItemName {
    font-size: 20px;
}
.ItemDelete {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    cursor: pointer;
}