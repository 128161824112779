.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Form {
    margin: 5px;
    width: 300px;
    border: 1px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FormLogin {
    margin: 5px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.FormPassword {
    margin: 5px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.FormButtons {
    margin: 5px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FormButtons button {
    margin-left: 5px;
}