.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.Title {
    margin-left: 12px;
    margin-top: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Reload {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.ItemsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    margin: 12px;
}

.Item {
    width: 96px;
    height: 96px;
    background-color: #FAFAFA;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 12px;

    overflow: hidden;
    justify-content: space-around;
    cursor: pointer;
}

.ItemImage {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: contain;

    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    border-color: #F3F4F6;
}
