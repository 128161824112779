.ModelEditorPage {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* touch-action: manipulation; */
}

.Navigation {
    width: 100%;
    height: 56px;

    display: flex;
    justify-content: space-between;

    border: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #F6F8FB;
}

.NavigationLeft {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 44px;
}

.NavigationLogo {
    width: 120px;
    height: 40px;
}
.NavigationLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.NavigationHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    text-decoration: none;
    color: #70706B;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
}

.NavigationExport {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-radius: 8px;

    /* background-color: #FF6500; */
    background: linear-gradient(90deg, #FF6500 0%, #E5005A 100%);

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;

    color: #FFFFFF;
    cursor: pointer;
}

.NavigationUndoRedo {
    display: flex;
    flex-direction: row;
    gap: 10px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;

    color: #70706B;
}

.Undo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    
    cursor: pointer;
}

.Redo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    cursor: pointer;
}

.UndoRedoDisabled {
    cursor: default;
    opacity: 0.5;
}

.Content {
    width: 100%;
    height: calc(100% - 56px);

    display: flex;
}

.Categories {
    width: 80px;
    min-width: 80px;
    height: 100%;

    display: flex;
    flex-direction: column;

    background-color: #FAFAFA;

    border: 0px;
    /* border-right: 1px; */
    border-left: 2px;
    border-style: solid;
    border-color: #FAFAFA;
}

.Category {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    padding-top: 23px;
    padding-bottom: 23px;
    /* border-radius: 8px; */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    
    cursor: pointer;

    color: #202021;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 11px;
    line-height: 16px;
}

.CategorySelected {
    background-color: #FFFFFF;
}

.LeftPanel {
    width: 344px;
    min-width: 344px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LeftPanelButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    border: 0px;
    border-top: 2px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #F3F4F6;
}

.LeftPanelCanvas {
    /* bottom: 0; */
    width: 344px;
    height: 344px;
    /* overflow: hidden; */
    background-color: #F3F4F6;
    /* touch-action: none; */
}

.CenterPanel {
    width: 100%;
    /* height: 100%; */

    /* overflow: hidden; */
    background-color: #F3F4F6;
    display: flex;
    flex-direction: column;
    /* touch-action: none; */
}

.CenterPanelButtons {
    padding: 12px;
}

.RightPanel {
    width: 344px;
    min-width: 344px;
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    touch-action: pan-y;
}

.RightPanelLayers {
    width: 100%;
    height: 100%;
}

.DrawableState {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.IconButton {
    cursor: pointer;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 12px; */

    background-color: #F3F4F6;
    color: #202021;
}

.IconButton:active {
    background-color: #ccc;
    color: #202021;
}

.IconButtonSelected {
    background-color: #23813E;
    color: #ffffff;
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    /* .Navigation {
        height: calc(56px * 0.75);
    } */
    
    /* .NavigationLeft {
        margin-top: calc(8px * 0.75);
        margin-bottom: calc(8px * 0.75);
        margin-left: calc(16px * 0.75);
        gap: calc(44px * 0.75);
    } */
    
    /* .NavigationLogo {
        width: calc(120px * 0.75);
        height: calc(40px * 0.75);
    } */
    
    /* .NavigationHome {
        gap: calc(6px * 0.75);
        font-size: calc(14px * 0.75);
        line-height: calc(20px * 0.75);
    } */
    
    /* .NavigationExport {
        gap: calc(4px * 0.75);
    
        margin-top: calc(8px * 0.75);
        margin-bottom: calc(8px * 0.75);
        margin-right: calc(16px * 0.75);
    
        padding-left: calc(16px * 0.75);
        padding-right: calc(16px * 0.75);
        padding-top: calc(10px * 0.75);
        padding-bottom: calc(10px * 0.75);
    
        border-radius: calc(8px * 0.75);
        font-size: calc(14px * 0.75);
        line-height: calc(20px * 0.75);
    } */
    
    /* .NavigationUndoRedo {
        gap: calc(10px * 0.75);
        font-size: calc(14px * 0.75);
        line-height: calc(20px * 0.75);
    } */
    
    /* .Undo {
        gap: calc(4px * 0.75);
    } */
    
    /* .Redo {
        gap: calc(4px * 0.75);
    } */
    
    /* .Content {
        height: calc(100% - (56px * 0.75));
    } */
    
    .Categories {
        width: calc(80px * 0.75);
        min-width: calc(80px * 0.75);
        /* border-left: calc(2px * 0.75); */
    }
    
    .Category {
        /* gap: calc(8px * 0.75); */
    
        padding-top: calc(23px * 0.75);
        padding-bottom: calc(23px * 0.75);
        /* border-radius: calc(8px * 0.75); */
        /* border-top-left-radius: calc(8px * 0.75);
        border-bottom-left-radius: calc(8px * 0.75); */
        /* font-size: calc(11px * 0.75); */
        /* line-height: calc(16px * 0.75); */
    }
    
    .LeftPanel {
        width: calc(344px * 0.75);
        min-width: calc(344px * 0.75);
    }
    
    /* .LeftPanelButtons {
        padding: calc(12px * 0.75);
    
        border-top: calc(2px * 0.75);
        border-bottom: calc(2px * 0.75);
    } */
    
    .LeftPanelCanvas {
        width: calc(344px * 0.75);
        height: calc(344px * 0.75);
    }
    
    /* .CenterPanelButtons {
        padding: calc(12px * 0.75);
    } */
    
    .RightPanel {
        width: calc(344px * 0.75);
        min-width: calc(344px * 0.75);
    }
    .DrawableState {
        gap: calc(12px * 0.75);
    }
    
    /* .IconButton {
        border-radius: calc(32px * 0.75);
        width: calc(32px * 0.75);
        height: calc(32px * 0.75);
    } */
    
}