.ThreeScene {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.Message {
    position: absolute;
    font-size: 50;
    text-align: center;
    color: white;
    z-index: 1;
}