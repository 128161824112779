.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.close {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
    color: #FF320A;
}
  
.display {
    display: block;
}
  
.hidden {
    display: none;
}
  
.main {
    position: fixed;
    background: white;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 10px 10px 10px;
    border-radius: 12px;
    border-width: 2px;
    border-color: #F3F4F6;
    border-style: solid;
}