.PencilConfigurator {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    border: 0;
    border-bottom: 1px;
    border-top: 1px;
    border-style: solid;
    border-color: #F3F4F6;
    gap: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021
}

.Title {
    text-align: center;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.Group {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.Fill {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 4px;

    padding: 14px;
    border-radius: 12px;
    background-color: #F3F4F6;
}
.Clear {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 4px;

    padding: 14px;
    border-radius: 12px;
    background-color: #F3F4F6;
}

.HexName {
    width: 20%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;

    background-color: #F3F4F6;
    border-radius: 12px;
}

.Input {
    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.HexInput {
    width: 80%;
}

.SizeInput {
    -moz-appearance: textfield;
}

.SizeInput::-webkit-outer-spin-button,
.SizeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 34px;
}
.Switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 12px;
}

.Slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

input:checked + .Slider {
    background-color: #23813E;
}

input:focus + .Slider {
    box-shadow: 0 0 1px #23813E;
}

input:checked + .Slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.Slide {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}
.SlideInput {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 30px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 8px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.SlideInput::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 18px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.SlideInput::-moz-range-thumb {
    width: 16px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    .PencilConfigurator {
        padding: calc(0.75 * 16px);
        gap: calc(0.75 * 12px);
        /* font-size: calc(0.75 * 14px);
        line-height: calc(0.75 * 20px); */
    }
    
    .Title {
        /* font-size: calc(0.75 * 16px);
        line-height: calc(0.75 * 20px); */
    }
    
    .Row {
        gap: calc(0.75 * 8px);
    }
    
    .Group {
        gap: calc(0.75 * 8px);
    }
    
    .Fill {
        gap: calc(0.75 * 4px);
    
        padding: calc(0.75 * 14px);
        /* border-radius: calc(0.75 * 12px); */
    }
    .Clear {
        gap: calc(0.75 * 4px);
    
        padding: calc(0.75 * 14px);
        /* border-radius: calc(0.75 * 12px); */
    }
    
    .HexName {
        padding-left: calc(0.75 * 20px);
        padding-right: calc(0.75 * 20px);
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
    
        border-radius: calc(0.75 * 12px);
    }
    
    .Input {
        border-radius: calc(0.75 * 12px);
    
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
        /* font-size: calc(0.75 * 14px);
        line-height: calc(0.75 * 20px); */
    }
    
    /* .Switch {
        width: calc(0.75 * 50px);
        height: calc(0.75 * 34px);
    } */
    
    /* .Slider {
        border-radius: calc(0.75 * 12px);
    } */
    
    /* .Slider:before {
        height: calc(0.75 * 26px);
        width: calc(0.75 * 26px);
        left: calc(0.75 * 4px);
        bottom: calc(0.75 * 4px);
        border-radius: calc(0.75 * 10px);
    } */
    
    /* input:checked + .Slider:before {
        -webkit-transform: translateX(0.75 * 16px);
        -ms-transform: translateX(0.75 * 16px);
        transform: translateX(0.75 * 16px);
    } */
}