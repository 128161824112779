.Main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TitleBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: 1px;
    border-style: solid;
    background-color: #E37222;
}

.Close {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 10px;
    align-items: center;
}

.Upload {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-right: 10px;
    align-items: center;
}

.Title {
    font-size: 25px;
    font-weight: bold;
}

.ModelForm {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 30px;
    border: 1px;
    border-style: solid;
}

.ModelData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 0;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    align-items: center;
}

.ModelName {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModelNameInput {
    margin-left: 5px;
}

.ModelFile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModelFileInput {
    margin-left: 5px;
}

.ModelPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ModelPreviewInput {} 

.ModelPreviewImage {
    width: 200px;
    height: 100px;
    border: 1px;
    border-style: solid;
} 
.ModelPreviewImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ModelOverlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 0;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    align-items: center;
}

.ModelOverlayName {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ModelOverlayNameInput {
    margin-left: 5px;
}
.ModelOverlayFile {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ModelOverlayFileInput {}

.ModelOverlayImage {
    width: 120px;
    height: 120px;
    border: 1px;
    border-style: solid;
}

.ModelOverlayImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ModelTemplates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border: 0;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
}

.ModelTemplateAdd {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ModelTemplateForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 150px;
    border: 1px;
    border-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
}

.ModelTemplateName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ModelTemplateNameInput {
    margin-left: 5px;
}

.ModelTemplateFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModelTemplateFileInput {}

.ModelTemplateImage {
    width: 120px;
    height: 120px;
    border: 1px;
    border-style: solid;
}
.ModelTemplateImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ModelTemplateAddButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.ModelTexturesGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px;
    border-style: solid;
    margin-top: 5px;
    padding: 5px;
}

.ModelTextureItem {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    justify-content: space-between;
    border: 1px;
    border-style: solid;
    margin: 5px;
    overflow: hidden;
    align-items: center;
}

.TextureRemove {
    cursor: pointer;
}

.TextureImage {
    width: 150px;
    height: 150px;
}

.TextureImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ModelParts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border: 0;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
}

.ModelPartAdd {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ModelPartForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 150px;
    border: 1px;
    border-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
}

.ModelPartName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ModelPartNameInput {
    margin-left: 5px;
}

.ModelPartFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModelPartFileInput {}

.ModelPartImage {
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    border: 1px;
    border-style: solid;
}

.ModelPartImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ModelPartPath {
    cursor: pointer;
}

.ModelPartAddButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}