.Category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.Title {
    margin-left: 12px;
    margin-top: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin: 12px;
}

.Item {
    width: 96px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    cursor: pointer;

    border-radius: 12px;

    background-color: #FAFAFA;
}

.Add {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.ItemImage {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: contain;

    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    border-color: #F3F4F6;
}

.Uploader {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    padding-top: 32px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021
}

.UploaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.URLInput {
    width: 100%;
    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.FileInput {
    display: none;
}

.Button {
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
    
    background-color: #F3F4F6;
    border-radius: 12px;
}