.Container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 0px;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #F3F4F6;
    gap: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021
}

.Title {
    text-align: center;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.Wrap {
    flex-wrap: wrap;
}

.Group {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.TextArea {
    background-color: #F3F4F6;
}

.StyleButton {
    width: 30px;
    height: 25px;
}

.IconButton {
    cursor: pointer;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 12px; */

    background-color: #F3F4F6;
    color: #202021;
}

.IconButton:active {
    background-color: #ccc;
    color: #202021;
}

.IconButtonSelected {
    background-color: #23813E;
    color: #ffffff;
}

.IconCheckbox {
    cursor: pointer;
    border-radius: 2px;
    width: 25px;
    height: 25px;
    margin: 1px;
}

.IconCheckbox:active {
    background-color: #E37222;
}

.NumberInput {
    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;

    -moz-appearance: textfield;
}

.NumberInput::-webkit-outer-spin-button,
.NumberInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Select {
    min-width: 200px;
    position: relative;
}

.Select select {
    appearance: none;
    -webkit-appearance: none;

    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;

    background-color: #F3F4F6;
    border: 0;
    border-radius: 12px;

    cursor: pointer;
}

.SelectArrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none;
}

.HexName {
    width: 20%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;

    background-color: #F3F4F6;
    border-radius: 12px;
}

.HexInput {
    width: 80%;

    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 34px;
}
.Switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 12px;
}

.Slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

input:checked + .Slider {
    background-color: #23813E;
}

input:focus + .Slider {
    box-shadow: 0 0 1px #23813E;
}

input:checked + .Slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.Slide {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}
.SlideInput {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 30px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 8px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.SlideInput::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 18px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.SlideInput::-moz-range-thumb {
    width: 16px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    .Container {
        padding: calc(0.75 * 16px);
        gap: calc(0.75 * 12px);
    }
    
    .Row {
        gap: calc(0.75 * 8px);
    }
    
    .Group {
        gap: calc(0.75 * 8px);
    }
    
    .NumberInput {
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
    }

    .Select {
        min-width: calc(0.75 * 200px);
    }
    
    .Select select {
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
    }
    
    .HexName {
        padding-left: calc(0.75 * 20px);
        padding-right: calc(0.75 * 20px);
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
    }
    
    .HexInput {
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
    }    
}