.Main {
    position: absolute;
    z-index: 4;
    border-style: solid;
    border-color: gray;
}

.Resize {
    position: absolute;
    z-index: 5;
    background-color: lime;
}

.Rotate {
    position: absolute;
    z-index: 5;
    background-color: lime;
}

.DiagonalLeft {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0%;
    width: 2px; /* Set the line thickness */
    height: 141.42%;
    background-color: gray; /* Set the line color */
    transform-origin: 0% 0%;
    transform: rotate(-45deg);
}

.DiagonalRight {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 100%;
    width: 2px; /* Set the line thickness */
    height: 141.42%;
    background-color: gray; /* Set the line color */
    transform-origin: 0% 0%;
    transform: rotate(45deg);
}