.ExportOptions {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    padding-top: 32px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.Button {
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
    
    background-color: #F3F4F6;
    border-radius: 12px;
}

.Disabled {
    cursor: default;
    opacity: 0.5;
}