.Container {
    width: 70vw;
    height: 51vh;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
}

.Creator {
    position: relative;
    width: 50vh;
    height: 50vh;
    border: 1px;
    border-style: solid;
}

.BackImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
}

.FrontCanvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
}

.Clear {
    cursor: pointer;
}

.Confirm {
    cursor: pointer;
}