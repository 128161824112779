.Container {
    display: flex;
    flex-direction: column;
    border: 0px;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    padding: 16px;
    border-color: #F3F4F6;
    gap: 10px;
}

.Title {
    text-align: center;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Input {
    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;

    -moz-appearance: textfield;
}

.Input::-webkit-outer-spin-button,
.Input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Select {
    min-width: 200px;
    position: relative;
}

.Select select {
    appearance: none;
    -webkit-appearance: none;

    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;

    background-color: #F3F4F6;
    border: 0;
    border-radius: 12px;

    cursor: pointer;
}

.SelectArrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none;
}

.Slide {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}
.SlideInput {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 30px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 8px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.SlideInput::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 18px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.SlideInput::-moz-range-thumb {
    width: 16px; /* Set a specific slider handle width */
    height: 33px; /* Slider handle height */
    border-radius: 8px;
    background: #23813e; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    .Container {
        padding: calc(0.75 * 16px);
        gap: calc(0.75 * 10px);
    }
    
    /* .Title { */
        /* font-size: calc(0.75 * 16px);
        line-height: calc(0.75 * 20px); */
    /* } */

    /* .Row { */
        /* font-size: calc(0.75 * 16px);
        line-height: calc(0.75 * 20px); */
    /* } */
    
    .Input {
        border-radius: calc(0.75 * 12px);
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
        /* font-size: calc(0.75 * 14px);
        line-height: calc(0.75 * 20px); */
    }
    
    .Select {
        min-width: calc(0.75 * 200px);
    }
    
    .Select select {
        padding-top: calc(0.75 * 14px);
        padding-bottom: calc(0.75 * 14px);
        padding-left: calc(0.75 * 12px);
        padding-right: calc(0.75 * 12px);
        /* font-size: calc(0.75 * 14px);
        line-height: calc(0.75 * 20px); */
        border-radius: calc(0.75 * 12px);
    }
    
    .SelectArrow {
        right: calc(0.75 * 12px);
    }
}