.Category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.Title {
    margin-left: 12px;
    margin-top: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Container {
    margin: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

.Item {
    position: relative;
    width: 96px;
    height: 96px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    cursor: pointer;

    border-radius: 12px;

    background-color: #FAFAFA;

    font-weight: normal;
}

.Selected {
    font-weight: bold;
}

.ItemName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: inherit;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #202021;
}

.ItemImage {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: contain;

    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    border-color: #F3F4F6;
}

.Check {
    position: absolute;
    bottom: 8px;
    left: 8px;

    color: #23813E
}

.NoCheck {
    visibility: hidden;
}