.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.Title {
    margin-left: 12px;
    margin-top: 12px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Row {
    width: calc(100% - 24px);
    margin: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.Name {
    width: 100%;
    border-radius: 12px;
    background-color: #F3F4F6;
    border: 0;

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202021;
}

.Add {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.Group {
    display: flex;
    flex-direction: row;
}

.FontSize {
    width: 50px;
}

.StyleButton {
    width: 30px;
    height: 25px;
}