.ControlDrawable {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.InnerContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.PartOverlay {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    pointer-events: none;
}