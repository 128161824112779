.ModelSelectPage {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F3F4F6;
}

.LoadingMessage {
    font-size: 30px;
}

.ErrorMessage {
    font-size: 30px;
}

.TitleBar {
    width: 100%;
    height: 56px;
    /* padding-top: 24px; */
    /* padding-bottom: 24px; */
    /* padding-left: 42px; */
    /* padding-right: 42px; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: center;
    border: 0;
    border-bottom: 1px;
    border-style: solid;
    border-color: #F3F4F6;
    background-color: #ffffff;
}

.TitleBarLeft {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 44px;
}

.TitleBarLogo {
    width: 120px;
    height: 40px;
}
.TitleBarLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Content {
    height: 100%;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 24px;
    padding-bottom: 24px;

    display: flex;
    flex-direction: column;
    overflow-x: auto;
    gap: 20px;
}

.ModelsPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ViewButtons {
    display: flex;
    flex-direction: row;
    gap: 6px;

    border-radius: 12px;
    padding-left: 10px;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 6px;

    background-color: #ffffff;
}

.IconButton {
    cursor: pointer;
    width: 28px;
    height: 28px;

    border-radius: 8px;
    border: 2px;
    border-style: solid;
    border-color: #F3F4F6;
    /* padding: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    color: #202021;
}

.IconButton:active {
    background-color: #F3F4F6;
    color: #202021;
}

.IconButtonSelected {
    background-color: #FFF7F2;
    border-color: #FFF7F2;
    color: #FF6501;
}

.ActionMessage {
    font-size: 30px;
}

.AddModelButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}


.ModelsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.ModelsListItem {
    width: calc(50% - 8px);
    background-color: #ffffff;
    border-radius: 16px;
}

/* .ModelsListItem:hover {
    background-color: #07889B;
    border-color: #66B9BF;
} */

.ListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 12px;
    gap: 12px;
}

.ListPreview {
    width: 80px;
    height: 80px;
    border: 2px;
    border-radius: 8px;
    border-style: solid;
    border-color: #F3F4F6;
    overflow: hidden;
    background-color: #F3F4F6;
    
    display: flex;
    align-items: center;

    color: #1F1F18;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    /* font-weight: 600; */
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
}

.ListPreviewImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ListInfo {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
}

.ListName {
    color: #1F1F18;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
}
.ListLikes {
    display: flex;
    flex-direction: row;
    gap: 4px;

    color: #70706B;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}

.ListChangeButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: green;
}

.ListDeleteButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: red;
}

.ModelsGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}

.ModelsGridItem {
    border-radius: 16px;
    /* width: 252px; */
    /* height: 300px; */
    background-color: #ffffff;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.GridPreview {
    width: 212px;
    height: 200px;
    border: 2px;
    border-radius: 8px;
    border-style: solid;
    border-color: #F3F4F6;
    overflow: hidden;
    background-color: #F3F4F6;
    
    display: flex;
    align-items: center;
    justify-content: center;

    color: #1F1F18;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    /* font-weight: 600; */
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
}

.GridPreviewImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.GridInfo {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
}

.GridName {
    color: #1F1F18;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
}
.GridLikes {
    display: flex;
    flex-direction: row;
    gap: 4px;

    color: #70706B;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}

.Banner {
    position: relative;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 16px;
    background-color: #10163E;
    /* box-shadow: 0px 8px 40px -4px #1F1F1808; */
}

.HammerGuy {
    position: absolute;
    width: 132px;
    /* height: 212px; */
    left: 4px;
    top: 0px;
}

.NerfGirl {
    position: absolute;
    height: 233px;

    left: 89px;
    bottom: 0px;
}

.TridentGuy {
    position: absolute;
    width: 232px;

    right: 188px;
    top: 23px;
}

.BannerText {
    color: #ffffff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 26px;
    line-height: 33px;

    width: 483px;
    z-index: 1;
}

.BannerBack {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
}

.Drone {
    position: absolute;
    width: 160px;
    height: 90px;
    top: -15px;
    left: 290px;
    opacity: 0px;
    transform: rotate(166.08deg) scaleY(-1);
}

.Memoji {
    position: absolute;
    width: 180px;
    right: -13px;
    bottom: 0px;
}

.Plunger {
    position: absolute;
    width: 92px;
    top: -10px;
    right: 0px;
    transform: rotate(-5.74deg);
}